<template>
    <div v-if="user">
        <div class="flex items-center justify-between border rounded-t-sm px-4 bg-white shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': form.status == 'Draft'}">DRAFT</button></li>
                    <li><button :class="{'active': form.status == 'Waiting'}">WAITING</button></li>
                    <li><button :class="{'active': form.status == 'Ready'}">READY</button></li>
                    <li><button :class="{'active': form.status == 'To Validate'}">TO VALIDATE</button></li>
                    <li><button :class="{'active': form.status == 'Done'}">DONE</button></li>
                    <li v-if="form.status == 'Cancelled'"><button :class="{'active': form.status == 'Cancelled'}">CANCELLED</button></li>
                </stepper>
            </div>
            <div v-if="form.status == 'Ready'">
                <button @click="onConfirm" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CONFIRM</button>
            </div>
            <div v-if="form.status == 'To Validate' && user.role == 'Admin'">
                <button @click="onSetToReady" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SET TO READY</button>
                <button @click="onValidate" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">VALIDATE</button>
            </div>
        </div>

        <div class="m-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                    </div>
                </div>
            </div>

            <!-- OVERVIEW -->
            <div v-show="tab=='overview'" class="animated fadeIn faster">
                <div class="grid grid-cols-9 p-5 divide-x bg-white border border-t-0 border-b-0">
                    <div class="col-span-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{form.receiveNo}}</div>
                                <div class="text-gray-400 text-xs">RECEIVE#</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg font-medium">{{purchase}}</div>
                                <div class="text-gray-400 text-xs">PURCHASE ORDER #</div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="" class="font-medium">SUPPLIER</label>
                        </div>
                    </div>

                    <div class="col-span-4 pl-5">
                        <div class="mb-5">
                            <div class="grid grid-cols-2 gap-5">
                                <div class="col-span-1">
                                        <label for="" class="font-medium">SCHEDULED DATE</label>
                                    <div>{{getDate(form.scheduled)}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5 mb-3">
                            <div class="col-span-1">
                                <label class="font-medium" for="">KURIR / EXPEDISI</label>
                                <div v-if="form.shipping">
                                    <div v-if="form.shipping.name">{{form.shipping.name.name}}</div>
                                    <div v-else>--</div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div>TRACKING NUMBER</div>
                                <div v-if="form.shipping" class="flex items-center">
                                    <div v-if="form.shipping.trackingNumber">{{form.shipping.trackingNumber}}</div>
                                    <div v-else>--</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="w-full table-fixed bg-white">
                    <thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  sticky z-10 text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 bg-blue-500" style="width: 48%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">ORDERED</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 2%; top: 93px"></th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">DONE</th>
                            <th class="py-2.5 px-5 pr-5 sticky z-10 text-blue-50 text-center bg-blue-500" style="width: 15%; top: 93px">SN</th>
                        </tr>
                    </thead>
                    <tbody ref="body" class="border border-b-0">
                        <tr v-for="(item, i) in form.items" :key="i" @click="onEdit(i)" class="border-b hover:bg-gray-100 group cursor-pointer" :class="[item.qty > item.done || item.qty < item.done || item.qty > item.stock ? 'text-red-500': 'text-green-600']">
                            <td :ref="'td'+i" class="pl-5 h-9 border-r relative" :class="{'bg-gray-100': item.edited}">
                                <div v-show="item.edited" :ref="'tick'+i" class="absolute -left-px -top-px w-2 bg-teal-500"></div>
                                {{i+1}}
                            </td>
                            <td class="px-2 h-9 border-r border-transparent truncate" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{item.name}}</td>
                            <td class="px-2 h-9 border-r border-transparent text-right" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">
                                <div v-if="user">
                                    <div v-if="user.role == 'Admin'">{{item.qty}}</div>
                                </div>
                            </td>
                            <td class="h-9 px-1 border-r border-transparent text-center" :class="{'bg-gray-100': item.edited}">
                                <a tabindex="-1" href="#" :class="[item.qty > item.stock ? 'text-red-500':'text-green-500']"><i class="icon-chart"></i></a>
                            </td>
                            <td class="border-r">
                                <autonumeric
                                    :disabled="status"
                                    :ref="'done'+i"
                                    :class="[{'group-hover:bg-white': item.edited && !status}, {'bg-red-500 text-red-50 group-hover:bg-red-500': item.err}]"
                                    @keydown.native.enter="onEnter(i)"
                                    v-model="item.done"
                                    class="h-9 w-full focus:outline-none px-2 text-right group-hover:bg-gray-100"
                                    :options="['integer', {modifyValueOnWheel : false}]" 
                                >
                                </autonumeric>
                            </td>
                            <td class="text-center">
                                <a tabindex="-1" @click.stop="showSerialNumber(item.idx)" href="#" class="h-9 flex items-center justify-center" :class="[item.isSerialNumber ? 'text-gray-500 hover:text-blue-500':'text-gray-300 cursor-not-allowed pointer-events-none']"><i class="icon-list-unordered"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- MODAL BACK ORDER -->
        <modal :show="modalBackOrder">
            <div class="w-2/5 bg-white rounded-sm border">
                <div class="py-3 px-5 flex border-b">
                    <div>CREATE BACKORDER?</div>
                </div>
                <div class="p-5">
                    You have processed less products than the initial ordered.
                    <ul class="p-2 bg-blue-100 rounded-sm mt-1.5">
                        <li>Create a backorder if you expect to process the remaining products later.</li>
                        <li>Do not create a backorder if you will not process the remaining products.</li>
                    </ul>
                </div>
                <div>
                    <div class="py-2 text-right px-5 border-t">
                        <button @click="modalBackOrder= false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                        <!-- <button type="submit" class="bg-teal-500 h-9 px-5 rounded-sm text-teal-50 hover:bg-teal-600 mr-2">NO BACKORDER</button> -->
                        <button @click="onCreateBackOrder" type="submit" class="bg-teal-500 h-9 px-5 rounded-sm text-teal-50 hover:bg-teal-600">CREATE BACKORDER</button>
                    </div>
                </div>
            </div>
        </modal>

         <!-- MODAL SERIAL NUMBER -->
        <modal :show="modalSerialNumber">
            <div class="w-2/5 bg-white rounded-sm border">
                <div>
                    <div class="flex items-center justify-between px-5 py-3 border-b">
                        <div>ENTRY SERIAL NUMBER</div>
                    </div>
                    <div class="px-5 font-medium mt-2">{{formSerialNumber.name}}</div>
                    <div class="grid grid-cols-2 gap-5 py-3 px-5">
                        <div class="col-span-1">
                            <input :disabled="status && user.role=='Staff'" v-model="formSerialNumber.serial" @keydown.enter="onEnterSerialNumber" type="text" class="form-control" placeholder="Serial Number">
                        </div>
                        <div class="flex items-center justify-end">
                            <div>
                                <div v-if="user.role == 'Admin'" class="border-b-2 border-green-500">Ordered: {{formSerialNumber.serialLength}}</div>
                            </div>
                            <div class="ml-5">
                                <div class="border-b-2 border-blue-500">SN Count: {{formSerialNumber.serialNumber.length}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="onSubmitSerialNumber">
                    <div class="px-5">
                        <div class="border-b">SERIAL NUMBER</div>
                        <ul ref="bodyModal" class="overflow-scroll">
                            <li v-for="(sn, i) in formSerialNumber.serialNumber" :key="i" class="flex items-center">
                                <div :ref="i" class="w-16">#{{i+1}}</div>
                                <input :disabled="user.role == 'Staff'" v-model="sn.sn" type="text" :class="{'border-red-500 text-red-500': formSerialNumber.errSerialNumber[i].err}" class="border-b w-full focus:outline-none focus:border-teal-500 h-7">
                            </li>
                        </ul>
                    </div>
                    <div class="py-2 text-right px-5 border-t">
                        <button @click="modalSerialNumber=false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                        <button :disabled="status && user.role == 'Staff' || formSerialNumber.serialNumber.length == 0" type="submit" class="h-9 px-5 rounded-sm text-teal-50" :class="[status && user.role == 'Staff' || formSerialNumber.serialNumber.length == 0 ? 'bg-teal-400 cursor-not-allowed':'hover:bg-teal-600 bg-teal-500']">APPLY</button>
                    </div>
                </form>
            </div>
        </modal>

        <!-- ALERT -->
        <div v-show="invalids.length > 0" class="fixed bg-red-200 z-50 animated fadeInDown faster top-5 w-72 right-5 text-black p-2 rounded-sm shadow-lg">
            <div class="font-medium flex justify-between items-center">
                <div>Invalid Fields:</div>
                <a @click.prevent="closeAlert" href="#" class="hover:text-red-600"><i class="icon-cross3"></i></a>
            </div>
            <div class="pl-5">
                <ul class="list-disc">
                    <li v-for="(invalid, i) in invalids" :key="i">{{invalid.text}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import stepper from '../../../components/stepper.vue'
import {moment} from '../../../moment'
import Autonumeric from '../../../components/Autonumeric.vue'
import Modal from '../../../components/Modal.vue'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
import {mapGetters} from 'vuex'
export default {
    components: {stepper, Autonumeric, Modal},
    mixins:[BreadcrumbManager],
    data () {
        return {
            tab: 'overview',
            task: 0,
            modalSerialNumber: false,
            modalBackOrder: false,
            purchase: '',
            form: {
                receiveNo: '',
                purchaseId: '',
                supplierId: '',
                warehouseId: '',
                scheduled: '',
                items: [],
                status: '',
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                    bookingCode: '',
                    trackingNumber: ''
                },
                receiveStatus: 'Done',
            },
            formSerialNumber : {
                serial: '',
                serialLength: '',
                index: '',
                name: '',
                serialNumber: [],
                errSerialNumber: []
            },
            idxSn: '',
            onCheckSerialNumber: false,
            invalids: [],
        }
    },
    mounted () {
        let h = window.innerHeight - 275
        this.$refs.bodyModal.style.maxHeight = h+'px'
        this.getData()
        window.addEventListener('click', this.onClose)
    },
    destroyed () {
        window.removeEventListener('click', this.onClose)
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        status () {
            if(this.form.status == 'Done' || this.form.status == 'Cancelled' || this.form.status == 'To Validate') {
                return true
            }
            return false
        }
    },
    methods: {
        onTab (i) {
            this.tab = i
        },
        getData () {
            axios.get('/receipts/detail/'+this.$route.params.id)
            .then(res => {
                this.setBreadcrumbs([
                    {text: 'Receipts', path: '/receipts'},
                    {text: res.data.receiveNo}
                ])
                this.form.items = []
                this.purchase = res.data.purchase
                this.form.receiveNo = res.data.receiveNo
                this.form.purchaseId = res.data.purchaseId
                this.form.supplierId = res.data.supplierId
                this.form.warehouseId = res.data.warehouseId
                this.form.scheduled = res.data.scheduled
                this.form.status = res.data.status
                const items = res.data.items
                for(let i=0; i < items.length; i++) {
                    const el = items[i]
                    this.form.items.push({idx: el.idx, productId: el.productId, name: el.name, isSerialNumber: el.isSerialNumber, serialNumber: el.serialNumber, qty: el.qty, done: el.done, edited: false, err: false })
                }
                this.form.shipping = res.data.shipping
            })
        },
        onEdit (i) {
            this.onClear()
            this.form.items[i].edited = true
            this.form.items[i].err = false
            let h = this.$refs['td'+i][0].clientHeight +2
            this.$refs['tick'+i][0].style.height = h+'px'
            this.$refs['done'+i][0].$el.focus()
        },
        onSaveReceive () {
            axios.put('/receipts/update/'+this.$route.params.id, this.form)
            .then(() => {
                this.getData()
            })
        },
        getDate (d) {
            return moment(d)
        },

        onEnter () {

        },
        showSerialNumber (idx) {
            this.modalSerialNumber = true
            let i = this.form.items.findIndex(obj => obj.idx == idx)
            let el = this.form.items[i]
            this.formSerialNumber.index = i
            this.formSerialNumber.name = el.name
            this.formSerialNumber.serialNumber = JSON.parse(JSON.stringify(el.serialNumber))
            this.formSerialNumber.serialLength = el.qty
            let a = el.serialNumber.length
            for (let i=0; i < a; i++) {
                this.formSerialNumber.errSerialNumber.push({err: ''})
            }
            return true
        },
        onEnterSerialNumber () {
            for(let i=0; i < this.formSerialNumber.errSerialNumber.length; i++) {
                this.formSerialNumber.errSerialNumber[i].err = ''
            }
            let sn = this.formSerialNumber.serialNumber.findIndex(obj => obj.sn === this.formSerialNumber.serial)
            if(sn != -1) {
                this.formSerialNumber.errSerialNumber[sn].err = true
                this.$refs[sn][0].scrollIntoView({ behavior: "smooth" })
            }
            if(this.formSerialNumber.serialNumber.length < this.formSerialNumber.serialLength && sn == -1) {
                this.formSerialNumber.serialNumber.push({sn: this.formSerialNumber.serial})
                this.formSerialNumber.errSerialNumber.push({err: ''})
                this.$nextTick(() => {
                    let h = this.$refs.bodyModal.scrollHeight
                    this.$refs.bodyModal.scrollTop = h
                    this.formSerialNumber.serial = ''
                })
            }
        },
        onSubmitSerialNumber () {
            let app = this
            const val = this.formSerialNumber.serialNumber.map(obj => {
                return obj.sn
            })
            const isDuflicate = val.some((obj, idx )=> {
                return val.indexOf(obj) != idx
            })
            if(isDuflicate) {
                let sn = val.filter((obj, idx) => {
                    return val.indexOf(obj) !== idx
                })
                for (let i=0; i < val.length; i++) {
                    for (let a=0; a < sn.length; a++) {
                        if(val[i] == sn[a]) {
                            this.formSerialNumber.errSerialNumber[i].err = true
                        }
                    }
                }
                return
            }
            for(let i=0; i < this.formSerialNumber.serialNumber.length; i++) {
                let el = this.formSerialNumber.serialNumber[i]
                if(!el.sn) {
                    this.formSerialNumber.errSerialNumber[i].err = true
                } else {
                    this.formSerialNumber.errSerialNumber[i].err = false
                }
            }

            if(emptySerialNumber()) {
                let i = this.formSerialNumber.index
                this.form.items[i].serialNumber = this.formSerialNumber.serialNumber
                this.form.items[i].done = this.formSerialNumber.serialNumber.length
                this.modalSerialNumber = false
                this.$nextTick(() => {
                    this.onSaveReceive()
                })
                if(this.onCheckSerialNumber) {
                    this.$nextTick(() => {
                        this.onConfirm()
                    })
                }
            }

            function emptySerialNumber () {
                for (let i=0; i < app.formSerialNumber.serialNumber.length; i++) {
                    const el = app.formSerialNumber.serialNumber[i]
                    if(!el.sn) {
                        return false
                    }
                } 
                return true
            }
        },
        onConfirm () {
            for (let el of this.form.items) {
                if(el.qty < el.done) {
                    this.invalids.push({text: 'Item, ordered with done not match'})
                    return
                }
                if(el.isSerialNumber && el.done != el.serialNumber.length) {
                    this.showSerialNumber(el.idx)
                    this.onCheckSerialNumber = true
                    return
                }
            }
            axios.post('/receipts/confirm/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        onSetToReady () {
            axios.post('/receipts/settoready/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        onValidate () {
            for (let el of this.form.items) {
                if(el.qty < el.done) {
                    this.invalids.push({text: 'Item, ordered with done not match'})
                    return
                }
                if(el.isSerialNumber && el.done != el.serialNumber.length) {
                    this.showSerialNumber(el.idx)
                    this.onCheckSerialNumber = true
                    return
                }
                if(el.qty > el.done) {
                    this.modalBackOrder = true
                    return
                }
            }
            axios.post('/receipts/validate/'+this.$route.params.id, this.form)
            .then(() => {
                this.getData()
            })
        },
        onCreateBackOrder () {
            let backorder = []
            this.form.receiveStatus = 'Partial'
            for (let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.done < el.qty) {
                    let demand = el.qty - el.done
                    backorder.push({idx: el.idx, productId: el.productId, isSerialNumber: el.isSerialNumber, serialNumber: [], qty: demand, done: 0})
                } 
            }
            let form = {
                _id: this.form.purchaseId,
                supplierId: this.form.supplierId,
                items: backorder,
                status: 'Ready',
                shipping: this.form.shipping
            }
            axios.post('/receipts/backorder', form)
            .then(() => {
                axios.post('/receipts/validate/'+this.$route.params.id, this.form)
                .then(() => {
                    this.getData()
                    this.modalBackOrder = false
                })

            })
        },
        onClose (e) {
            if(!e || !this.$refs.body.contains(e.target)) {
                this.onClear()
            }
        },
        onClear () {
            for(let i=0; i < this.form.items.length; i++) {
                this.form.items[i].edited = false
            }
        },
        closeAlert () {
            this.invalids = []
            clearTimeout(this.invalidTimeout)
        },
    },
    watch: {
        modalSerialNumber (val) {
            if(!val) {
                this.formSerialNumber.serial = ''
                this.formSerialNumber.name = ''
                this.formSerialNumber.index = ''
                this.formSerialNumber.serialNumber = []
                this.formSerialNumber.errSerialNumber = []
            }
        },
        invalids () {
            clearTimeout(this.invalidTimeout)
            this.invalidTimeout = setTimeout(this.closeAlert, 3500)
        },
    }
}
</script>